.mw_wp_form {
	.btn.btn-primary {
		position: relative;
		display: block;
		font-size: 2rem;
		font-weight: bold;
		padding: 0.25em 0.5em;
		text-decoration: none;
		color: #FFF;
		background: #00bcd4;
		transition: .3s;
		padding: 20px;
		border-radius: 8px;
		margin: 30px auto;
		width: 90%;
		border: none;
		&:hover {
			opacity: .7;
		}
	}
}