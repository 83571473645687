.p-table {
	font-size: 2rem;
	&.-arrow {
		width: 100%;
		border-collapse: collapse;
		tr {
			border-bottom: solid 2px white;
			&:last-child {
				border-bottom: none;
			}
			th {
				position: relative;
				text-align: left;
				width: 40%;
				background-color: #52c2d0;
				color: white;
				text-align: center;
				padding: 10px 0;
				&:after {
					display: block;
					content: "";
					width: 0px;
					height: 0px;
					position: absolute;
					top:calc(50% - 10px);
					right:-10px;
					border-left: 10px solid #52c2d0;
					border-top: 10px solid transparent;
					border-bottom: 10px solid transparent;
				}
			}
			td {
				text-align: left;
				width: 60%;
				text-align: center;
				background-color: #eee;
				padding: 10px 0;
			}
		}
		&.-green {
			tr {
				th {
					background-color: $green;
					&:after {
						border-left-color: $green;
					}
				}
			}
		}
	}
	@media(max-width: 767px) {
		font-size: 1.5rem;
	}
}