.p-list-option-channel {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 auto;
	max-width: 800px;
	>* {
		width: 18%;
		margin-bottom: 30px;
	}
	@media(max-width: 767px) {
		>* {
			width: 31%;	
		}
	}
}