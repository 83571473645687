.p-box {
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px 15px;
	font-weight: bold;
	&.-blue {
		border-color: $blue;
		color: $blue;

	}
	&.-red {
	}
}