/*------------------------------------
フッター
------------------------------------*/
.footer-btm {
    background-color: #ebf5f7;
    >.-inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 820px;
        margin: 0 auto;
        padding: 20px 0;
        >.-logo {
            margin-right: 60px;
            img {
                width: 100%;
                max-width: 180px;
            }
        }
        >.-info {
            h2 {
                color: $blue-dark;
                font-weight: bold;
                font-size: 22px;
                margin: 0 0 5px;
            }
            .-address {
                color: $blue-dark;
                margin: 0;
                font-size: 14px;
            }
        }
    }
    @media (max-width: 768px) {
        >.-inner {
            width: 100%;
            padding: 25px 15px;
            display: block;
            text-align: center;
            >.-logo {
                margin: 0;
                img {
                    max-width: 150px;
                    margin-bottom: 15px;
                }
            }
            >.-info {
                h2 {
                    font-size: 18px;
                }
            }
        }
    }
}

.copyright {
    background-color: #1a9acb;
    >.-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1080px;
        margin: 0 auto;
        padding: 10px 0;
        .-wrap-nav {
            display: flex;
            align-items: center;
            >.-list {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 0 0 5px;
                >.-item {
                    position: relative;
                    list-style-type: none;
                    padding: 0 20px 0 0;
                    margin: 0;
                    a {
                        color: #fff;
                        letter-spacing: .05em;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        background-image: url(../img/base/icon/i_arrow_right_wh.svg);
                        background-repeat: no-repeat;
                        width: 10px;
                        height: 10px;
                        top: 4px;
                        right: 0;
                        transition-duration: 200ms;
                    }
                    &:not(:last-child) {
                        margin-right: 25px;
                    }
                    &:hover {
                        &:after {
                            right: -3px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        >.-inner {
            width: 100%;
            padding: 0;
            .-wrap-nav {
                display: none;
                // >.-list {
                //     display: block;
                //     >.-item {
                //         display: inline-block;
                //     }
                // }
            }
        }
    }
}

