/*------------------------------------
トップページ
------------------------------------*/
.bg-top {
    background-image: url(../img/main/front-page/bg_top.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.top-container {
}

.news-danger {
    padding: 15px;
    background-image: url(../img/main/front-page/bg_news_danger.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 50px;
    >.-list {
        background-color: #fff;
        margin: 0;
        border: 1px solid #ccc;
        padding: 0 10px;
        >.-item {
            list-style-type: none;
            padding: 18px 10px;
            margin: 0;
            a {
                display: flex;
                justify-content: flex-start;
                position: relative;
                color: $black;
                .-date {
                    font-size: 14px;
                    width: 10em;
                }
                .c-cat {
                    margin-right: 30px;
                }
                .-title {
                    font-size: 16px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    background-image: url(../img/base/icon/i_arrow_right_gr.svg);
                    background-repeat: no-repeat;
                    width: 10px;
                    height: 10px;
                    top: 7px;
                    right: 0;
                    transition-duration: 200ms;
                }
                &:not(:last-child) {
                    margin-right: 25px;
                }
                &:hover {
                    &:after {
                        right: -3px;
                    }
                }
            }
            &:not(:last-child) {
                border-bottom: 1px solid #ccc;
            }
        }
    }
    @media(max-width: 767px) {
        >.-list {
            >.-item {
                a {
                    flex-wrap: wrap;
                    .c-cat {
                        margin-right: 0;
                    }
                    .-title {
                        margin-top: 10px;
                    }
                    &:after {
                        top: 40%;
                        right: -12px;
                    }
                }
            }
        }
    }
}

.area-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 50px;
    >.-col {

    }
    >.-item {
        >.-title-en {
            @extend .ff_futura;
            text-align: center;
            font-size: 22px;
            padding: 0;
            margin: 0;
            background: transparent;
            line-height: 1.2;
        }
        >.-title {
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            margin: 0 0 12px;
            border-bottom: none;
            padding: 0;
            &:after {
                content: none;
            }
        }
        a {
            display: block;
            margin-bottom: 15px;
        }
    }
    @media(min-width: 768px) {
        >.-col {
            width: 48%;
        }
    }
    @media(max-width: 767px) {
        >.-item {
            margin-bottom: 40px;
            >.-title {
                font-size: 22px;
            }
        }
    }


}

.area {
    padding: 50px 0 30px;
}

.area-service {
    >.-btn-list {
        >.-item {
            margin-bottom: 15px;
        }
    }
    @media (min-width: 768px) {
        >.-btn-list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            >.-item {
                width: 32%;
            }

        }
    }
}

.area-news {
    margin: 0;
    .-head-title {
        font-weight: bold;
        color: $blue;
        margin-bottom: 10px;
        font-size: 22px;
    }
    .-box {
        background-color: #fff;
        padding: 20px 15px;
        margin: 0 0 20px;
        .-list {
            padding: 0;
            margin: 0 0 20px;
            >.-item {
                list-style-type: none;
                padding: 10px 0;
                margin: 0;
                line-height: 1.6;
                border-bottom: 1px solid #ccc;
                a {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    color: $black;
                    .-date {
                        font-size: 14px;
                        width: 20%;
                    }
                    .c-cat {
                        width: 25%;
                        font-size: 12px;
                        >.-inner {
                            height: 24px;
                            line-height: 2;
                        }
                    }
                    .-title {
                        width: 55%;
                        font-size: 14px;
                        &.-lg {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
    @media(max-width: 767px) {
        .-head-title {
            text-align: center;
        }
        .-box {
            margin-bottom: 40px;
            .-list {
                >.-item {
                    a {
                        flex-wrap: wrap;
                        .-date {
                            width: 7em;
                        }
                        .c-cat {
                            margin-right: 0;
                        }
                        .-title {
                            margin-top: 10px;
                            width: 100%;
                        }
                        &:after {
                            top: 40%;
                            right: -12px;
                        }
                    }
                }
            }
        }
    }
}

.area-more-information {
    .-content {
        >.-wrap-tel {
            margin-bottom: 8px;
            >.-title {
                font-weight: bold;
                font-size: 28px;
                padding-top: 10px;
                >.-i {
                    width: 28px;
                    margin-right: 4px;
                    position: relative;
                    top: -1px;
                }
            }
            >.-tel {
                color: $blue;
                font-size: 60px;
                font-weight: bold;
                @extend .ff_roboto_condensed;
            }
            >.-sm {
                font-size: 20px;
                font-weight: bold;
                margin: 0;
            }
        }
        >.-wrap-btn {
            display: flex;
            >.-item {
                >.-desc {
                    text-align: justify;
                    font-size: 15px;
                    font-weight: bold;
                    line-height: 1.6;
                    margin-bottom: 8px;
                }
            }
        }
    }
    @media (min-width: 768px) {
        padding: 60px 0 50px;
        .area_title {
            margin-bottom: 40px;
        }
        .-content {
            display: flex;
            justify-content: space-between;
            >.-wrap-tel {
                width: 40%;
                border-left: 2px solid $blue-dark;
                padding-left: 15px;
            }
            >.-wrap-btn {
                width: 62%;
                justify-content: space-between;
                >.-item {
                    width: 32%;
                }
            }
        }
    }
    @media(max-width: 767px) {
        .-content {
            >.-wrap-tel {
                text-align: center;
                margin-bottom: 40px;
                >.-tel {
                    text-align: center;
                    font-size: 52px;
                }
            }
            >.-wrap-btn {
                display: block;
                >.-item {
                    width: 220px;
                    margin: 0 auto 20px;
                    >.-desc {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.area-sponsored {
    .-list {
        padding: 0;
        >.-item {
            display: inline-block;
            margin-bottom: 10px;
            img {
                display: block;
            }
            @media (min-width: 768px) {
                width: 23%;
                margin-right: 1%;
            }
            @media (max-width: 767px) {
                width: 48%;
                margin-right: 1%;
            }
        }
    }
}
