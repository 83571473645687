/*------------------------------------
ヘッダー
------------------------------------*/
.header-top {
    border-bottom: 1px solid #ccc;
    >.-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        padding: 10px 0;
        h2.copy {
            font-size: 13px;
            margin: 0;
        }
        >.-wrap-btn {
            display: flex;
            justify-content: flex-end;
            >.-btn {
                box-sizing: border-box;
                border: 1px solid #cdcdcd;
                color: #656565;
                text-align: center;
                position: relative;
                padding: 8px 10px 8px 35px;
                width: 140px;
                margin-left: 8px;
                font-size: 13px;
                >.-i {
                    position: absolute;
                    left: 8px;
                    top: 7px;
                    width: 18px;
                }
            }
        }
        .search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: #cbcbcb 1px solid;
            padding: 4px;
            color: #cbcbcb !important;
            border-radius: 0;
            margin-left: 15px;
            padding: 0 0 0 5px;
            label.word {
                line-height: 16px;
                margin: 0 4px 0 0 !important;
                padding: 0;
                .s {
                    width: 130px;
                    height: 16px;
                    color: #333;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 16px;
                    background: transparent !important;
                    border: none;
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                    outline: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
            }
            button {
                display: block;
                height: 100%;
                width: 30px;
                background: none;
                border: none;
                margin: 0 auto;
                padding: 0;
                opacity: 1;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-color: #ccc;
                i {
                    display: block;
                    font-size: 16px;
                    width: 16px;
                    height: 16px;
                    margin: auto;
                    color: #fff;
                }
                &:active {
                    opacity: 0.8;
                }
            }
        }
    }
}

.header-pc {
    border-bottom: 1px solid #ccc;
    >.-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        padding: 10px 0;
        >.-logo {
            h1 {
                img {
                    max-width: 180px;
                    width: 100%;
                }
            }
        }
        >.-wrap-nav {
            display: flex;
            align-items: center;
            >.-list {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                >.-item {
                    position: relative;
                    list-style-type: none;
                    padding: 0 20px 0 0;
                    margin: 0;
                    a {
                        font-weight: bold;
                        color: $black;
                        letter-spacing: .05em;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        background-image: url(../img/base/icon/i_arrow_right.svg);
                        background-repeat: no-repeat;
                        width: 10px;
                        height: 10px;
                        top: 7px;
                        right: 0;
                        transition-duration: 200ms;
                    }
                    &:not(:last-child) {
                        margin-right: 25px;
                    }
                    &:hover {
                        &:after {
                            right: -3px;
                        }
                    }
                }
            }
            >.-btn-contact {
                margin-left: 30px;
                img {
                    width: 180px;
                }
            }
        }
    }
}
