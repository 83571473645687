.c-attention {
	text-align: center;
	color: red;
	font-size: 46px;
	font-weight: bold;
	margin: 30px 0;
	@media (max-width: 768px) {
		font-size: 30px;
	}

}