/* au携帯をお使いならさらにおトク！ */
.au-smart .sec-container {
  padding: 80px 0;
}
.au-smart h2 {
  margin-bottom: 40px;
}
.au-smart h2 img {
  display: block;
  width: 812px;
  height: 100%;
  margin: 0 auto;
}
.au-smart .ausmartvalue {
  display: block;
  margin: 40px auto;
  width: 502px;
  height: 100%;
}
.au-smart .telandsp {
  display: block;
  margin: 40px auto;
  width: 718px;
  height: 100%;
}
.au-smart .commentary {
  position: relative;
  display: block;
  width: 100%;
  margin: 30px 0 0;
  padding: 20px 0;
  background: #fdfc00;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}
.au-smart .commentary:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: -20px;
  transform: translate(-50%, 0);
  border-style: solid;
  border-width: 0 15px 20px 15px;
  border-color: transparent transparent #fdfd00 transparent;
}
.au-smart .commentary p {
  margin: 0;
  color: #000055;
}
.au-smart .commentary p span {
  color: #e6292c;
}
.au-smart .einen {
  display: block;
  margin: 80px auto 40px;
  width: 800px;
  height: 100%;
}
.au-smart .box .top {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5c24;
  height: 100px;
}
.au-smart .box .top h4 {
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  margin: 0;
  background-color: transparent !important;
}
.au-smart .box .top h4.small {
  font-size: 0.8em;
}
.au-smart .box .bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #ff5c24;
  border-top: none;
  background: #fff;
  height: 120px;
}
.au-smart .box .bottom p {
  margin: 0;
  font-weight: bold;
  font-size: 1.3em;
  line-height: 1.25;
}
.au-smart .box .bottom p span {
  display: block;
  font-size: 1.5em;
  color: #ff5c24;
}
.au-smart .set {
  margin-top: 80px;
}
.au-smart .set h3 {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin: 0;
}
.au-smart .set .under_arrow {
  display: block;
  margin: 5px auto 30px;
  width: 514px;
  height: 100%;
}
.au-smart .set .middle {
  text-align: center;
  font-size: 1.3em;
  font-weight: bold;
  margin: 0 0 60px;
}
.au-smart .set .waribiki {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  margin: 0 auto;
}
.au-smart .set .waribiki .left {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 120px;
  background: #ff5c24;
}
.au-smart .set .waribiki .left:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -30px;
  top: 50%;
  transform: translate(0, -50%);
  border-style: solid;
  border-width: 60px 0 60px 30px;
  border-color: transparent transparent transparent #ff5c24;
}
.au-smart .set .waribiki .left p {
  font-size: 1.6em !important;
  color: #fff;
  line-height: 1.5 !important;
  margin: 0;
}
.au-smart .set .waribiki .right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  height: 100px;
}
.au-smart .set .waribiki .right p {
  font-size: 2em !important;
  line-height: 1 !important;
  margin: 5px 0;
}
.au-smart .set .waribiki .right p span {
  font-size: 1.5em;
  color: #ff5c24;
}
.au-smart .set .waribiki .right p.notice {
  font-size: 1em !important;
}

.au-smart .comments {
	width: 800px;
	margin: 40px auto 0;
}
.au-smart .comments p {
	font-size: 1em;
	line-height: 1.75;
	margin: 20px 0;
}
.au-smart .comments p .top {
	display: inline-block;
	margin-right: 5px;
	padding: 5px 10px;
	background: #666;
	color: #fff;
}


@media (max-width: 767px) {
  /* au携帯をお使いならさらにおトク！ */
  .au-smart .sec-container {
    padding: 40px 0;
  }
  .au-smart h2 {
    margin-bottom: 20px;
  }
  .au-smart h2 img {
    width: 100%;
  }
  .au-smart .ausmartvalue {
    margin: 40px auto;
    width: 251px;
  }
  .au-smart .telandsp {
    margin: 40px auto;
    width: 359px;
  }
  .au-smart .commentary {
    position: relative;
    display: block;
    width: 100%;
    margin: 20px 0 0;
    padding: 10px 5px;
    background: #fdfc00;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
  }
  .au-smart .einen {
    margin: 40px auto 20px;
    width: 100%;
  }
  .au-smart .box {
    margin-bottom: 10px;
  }
  .au-smart .box .top {
    height: auto;
    padding: 10px 0;
  }
  .au-smart .box .top h4 {
    font-size: 1.25em;
  }
  .au-smart .box .top h4.small {
    font-size: 0.8em;
  }
  .au-smart .box .bottom {
    border: 2px solid #ff5c24;
    height: auto;
    padding: 10px 0;
  }
  .au-smart .box .bottom p {
    font-size: 1.2em;
    line-height: 1.25;
  }
  .au-smart .box .bottom p span {
    font-size: 1.4em;
  }
  .au-smart .set {
    margin-top: 40px;
  }
  .au-smart .set h3 {
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    margin: 0;
  }
  .au-smart .set .under_arrow {
    display: block;
    margin: 5px auto 20px;
    width: 207px;
    height: 100%;
  }
  .au-smart .set .middle {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0 30px;
  }
  .au-smart .set .waribiki {
    display: block;
    width: 100%;
  }
  .au-smart .set .waribiki .left {
    width: 100%;
    height: 80px;
    margin-bottom: 30px;
  }
  .au-smart .set .waribiki .left:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 100%;
    bottom: -10px;
    transform: translate(-50%, 0);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #ff5c24 transparent transparent transparent;
  }
  .au-smart .set .waribiki .left p {
    font-size: 1.3em;
    line-height: 1.5;
    text-align: center;
  }
  .au-smart .set .waribiki .right {
    display: block;
    width: auto;
    height: auto;
  }
  .au-smart .set .waribiki .right p {
    font-size: 1.75em !important;
    margin: 20px 0 0;
    text-align: center;
  }
  .au-smart .set .waribiki .right p span {
    font-size: 1.25em;
  }
  .au-smart .set .waribiki .right p.notice {
    line-height: 1.25;
  }
  .au-smart .comments {
    width: 100%;
    margin: 20px auto 0;
  }
  .au-smart .comments p {
    font-size: 0.9em;
    line-height: 1.75;
    margin: 20px 0;
  }
  .au-smart .comments p .top {
    display: inline-block;
    margin-right: 5px;
    padding: 5px 10px;
    background: #666;
    color: #fff;
  }
  
}
