@charset "UTF-8";
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url("https://use.typekit.net/fhp7evy.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap");
.c-blue {
  color: #00a5e0 !important;
}

.bg-blue {
  background-color: #00a5e0 !important;
}

.c-blue-dark {
  color: #00729a !important;
}

.bg-blue-dark {
  background-color: #00729a !important;
}

.c-blue-light {
  color: #ebf5f7 !important;
}

.bg-blue-light {
  background-color: #ebf5f7 !important;
}

.c-black {
  color: #333 !important;
}

.bg-black {
  background-color: #333 !important;
}

.c-red {
  color: #cc0000 !important;
}

.bg-red {
  background-color: #cc0000 !important;
}

.c-orange-dark {
  color: #db9500 !important;
}

.bg-orange-dark {
  background-color: #db9500 !important;
}

.c-gray-light {
  color: #f5f5f5 !important;
}

.bg-gray-light {
  background-color: #f5f5f5 !important;
}

.c-gray {
  color: #e9e9e9 !important;
}

.bg-gray {
  background-color: #e9e9e9 !important;
}

.c-yellow {
  color: #f2c336 !important;
}

.bg-yellow {
  background-color: #f2c336 !important;
}

.text-red {
  color: #e40404;
}

.font-weight-bold {
  font-weight: bold;
}

@media (max-width: 767px) {
  .table-sp-block tbody tr th, .table-sp-block tbody tr td {
    display: block;
  }
}

@media (min-width: 768px) {
  .visible-sp {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .visible-pc {
    display: none !important;
  }
}

/*------------------------------------
フォント
------------------------------------*/
.ff_futura, .area-top > .-item > .-title-en, .area_title > .-title-en {
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.ff_roboto_condensed, .area-more-information .-content > .-wrap-tel > .-tel, .c-panel-support > .-lead > .-inner > .-tel {
  font-family: "Roboto Condensed", sans-serif;
}

/*------------------------------------
Base
------------------------------------*/
body {
  font-family: "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, sans-serif;
}

/*------------------------------------
Swiper
------------------------------------*/
#swiper-campaign {
  padding-bottom: 50px;
}

.swiper-button-next, .swiper-button-prev {
  top: 30%;
  width: 45px;
  height: 45px;
  background-size: 45px 45px;
}

.swiper-button-next {
  background-image: url(../img/base/swiper/next.png);
}

.swiper-button-prev {
  background-image: url(../img/base/swiper/prev.png);
}

/*------------------------------------
コンポーネント
------------------------------------*/
.c-cat {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  display: block;
}
.c-cat > .-inner {
  background-color: #cc0000;
  width: 8em;
  text-align: center;
  display: block;
}
.c-cat.program > .-inner {
  background: #6190ff;
}
.c-cat.support > .-inner {
  background: #00885a;
}
.c-cat.blog > .-inner {
  background: #ffa900;
}
.c-cat.top_news > .-inner {
  background: #e5171f;
}

.c-btn-banner img {
  width: 100%;
}

.c-btn-icon {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px 10px;
  font-weight: bold;
  font-size: 20px;
  background-color: #fff;
  color: #333;
  text-align: center;
}
.c-btn-icon > .-i {
  height: 24px;
  width: auto;
  margin-right: 5px;
}
.c-btn-icon:hover {
  opacity: 0.7;
  color: #333;
}

.c-btn-service {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px 20px;
  font-weight: bold;
  font-size: 20px;
  background-color: #f8f8f8;
  color: #333;
  display: block;
  position: relative;
}
.c-btn-service > .-inner {
  font-weight: bold;
  display: block;
  text-align: left;
  padding-left: 15px;
}
.c-btn-service > .-inner > .-i {
  height: 32px;
  width: auto;
  margin-right: 10px;
}
.c-btn-service > .-inner + .-text {
  display: block;
  border-top: 1px solid #ccc;
  color: #666666;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
  padding: 15px 0 0 15px;
}
.c-btn-service:after {
  content: "";
  position: absolute;
  background-image: url(../img/base/icon/i_arrow_right_gr.svg);
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  top: 30px;
  right: 20px;
  transition-duration: 200ms;
}
.c-btn-service:hover {
  opacity: 0.7;
  color: #333;
}
.c-btn-service:hover:after {
  right: 17px;
}
.c-btn-service.-wh {
  background-color: #fff;
}

.c-btn-more {
  font-size: 14px;
  color: #333;
  letter-spacing: 0.05em;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 25px 5px 10px;
  position: relative;
}
.c-btn-more:after {
  content: "";
  position: absolute;
  background-image: url(../img/base/icon/i_arrow_right.svg);
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  top: 10px;
  right: 3px;
  transition-duration: 200ms;
}
.c-btn-more:hover {
  color: #333;
}
.c-btn-more:hover:after {
  right: 0px;
}

.c-wrap-btn-right {
  display: flex;
  justify-content: flex-end;
}

.c-btn-contactbar {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  padding: 15px 5px;
  display: block;
}
.c-btn-contactbar > .-i {
  height: 70px;
  display: block;
  margin: 0 auto 10px;
}
.c-btn-contactbar > .-txt {
  font-weight: bold;
  text-align: center;
  display: block;
  color: #333;
  font-size: 14px;
}
.c-btn-contactbar > .-txt > .-inner {
  position: relative;
}
.c-btn-contactbar > .-txt > .-inner:after {
  content: "";
  position: absolute;
  background-image: url(../img/base/icon/i_arrow_right_yl.svg);
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  top: 6px;
  right: -20px;
  transition-duration: 200ms;
}

.area_title {
  margin-bottom: 30px;
}
.area_title > .-title-en {
  text-align: center;
  font-size: 22px;
  padding: 0;
  margin: 0 0 5px;
  background: transparent;
  line-height: 1.2;
}
.area_title > .-title-en > .-inner {
  display: inline-block;
  position: relative;
}
.area_title > .-title-en > .-inner:before {
  content: "";
  position: absolute;
  left: -20px;
  height: 30px;
  border-left: 2px solid #ccc;
  transform: rotate(-32deg);
}
.area_title > .-title-en > .-inner:after {
  content: "";
  position: absolute;
  right: -20px;
  height: 30px;
  border-left: 2px solid #ccc;
  transform: rotate(32deg);
}
.area_title > .-title-en > .-inner.c-black:before, .area_title > .-title-en > .-inner.c-black:after {
  border-color: #333;
}
.area_title > .-title-en > .-inner.c-blue:before, .area_title > .-title-en > .-inner.c-blue:after {
  border-color: #00a5e0;
}
.area_title > .-title-en > .-inner.c-orange-dark:before, .area_title > .-title-en > .-inner.c-orange-dark:after {
  border-color: #db9500;
}
.area_title > .-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 12px;
  border-bottom: none;
  padding: 0;
  line-height: 1.3;
}
.area_title > .-title:after {
  content: none;
}
.area_title > .-lead {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  line-height: 1.6;
}
@media (max-width: 767px) {
  .area_title > .-title-en {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .area_title > .-title-en > .-inner:before, .area_title > .-title-en > .-inner:after {
    height: 24px;
  }
  .area_title > .-title {
    font-size: 22px;
  }
}

.c-panel-service {
  padding: 30px 15px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
}
.c-panel-service > .-icon {
  height: 60px;
  margin: 0 auto 15px;
  display: block;
}
.c-panel-service > .-title {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin: 0 0 10px;
  background-color: transparent;
  padding: 0;
  color: #333;
}
.c-panel-service > .-lead {
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  color: #666666;
  margin: 0;
}

.c-panel-support {
  padding: 50px 15px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
}
.c-panel-support > .-wrap-icon {
  position: relative;
}
.c-panel-support > .-wrap-icon:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 50%;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.c-panel-support > .-wrap-icon > .-icon {
  height: 60px;
  margin: 0 auto 10px;
  display: block;
  position: relative;
  z-index: 1;
}
.c-panel-support > .-title {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin: 0 0 5px;
  background-color: transparent;
  padding: 0;
  color: #333;
}
.c-panel-support > .-lead {
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  color: #666666;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  min-height: 44px;
}
.c-panel-support > .-lead > .-inner > .-tel {
  display: block;
  margin: 0;
  color: #00a5e0;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}

.c-voice {
  padding: 0 20px 30px;
  position: relative;
  margin-top: 15px;
}
.c-voice:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -10px;
  background-image: url(../img/base/voice/bg_voice.png);
  width: 115px;
  height: 56px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
}
.c-voice > .-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.c-voice > .-header > .-icon {
  width: 46%;
}
.c-voice > .-header > .-info {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.c-voice > .-header > .-info > .-inner .-area {
  font-size: 16px;
}
.c-voice > .-header > .-info > .-inner .-area > .-inner {
  background-color: #f2c336;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 4px;
  line-height: 1.3;
}
.c-voice > .-header > .-info > .-inner > .-name {
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
}
.c-voice > .-header > .-info > .-inner > .-name > .-sm {
  font-size: 16px;
  margin-left: 3px;
}
.c-voice > .-header > .-info > .-inner > .-detail {
  font-size: 13px;
  font-style: italic;
}
.c-voice > .-content {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.8;
}
.c-voice > .-content > .-e {
  background-color: #d0ebf1;
}
@media (max-width: 767px) {
  .c-voice {
    padding: 0 50px 30px;
  }
  .c-voice:after {
    left: 10px;
  }
}

> .-wrap-btn {
  display: flex;
}
> .-wrap-btn > .-item > .-desc {
  text-align: justify;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 8px;
}

/*------------------------------------
ヘッダー
------------------------------------*/
.header-top {
  border-bottom: 1px solid #ccc;
}
.header-top > .-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  padding: 10px 0;
}
.header-top > .-inner h2.copy {
  font-size: 13px;
  margin: 0;
}
.header-top > .-inner > .-wrap-btn {
  display: flex;
  justify-content: flex-end;
}
.header-top > .-inner > .-wrap-btn > .-btn {
  box-sizing: border-box;
  border: 1px solid #cdcdcd;
  color: #656565;
  text-align: center;
  position: relative;
  padding: 8px 10px 8px 35px;
  width: 140px;
  margin-left: 8px;
  font-size: 13px;
}
.header-top > .-inner > .-wrap-btn > .-btn > .-i {
  position: absolute;
  left: 8px;
  top: 7px;
  width: 18px;
}
.header-top > .-inner .search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: #cbcbcb 1px solid;
  padding: 4px;
  color: #cbcbcb !important;
  border-radius: 0;
  margin-left: 15px;
  padding: 0 0 0 5px;
}
.header-top > .-inner .search label.word {
  line-height: 16px;
  margin: 0 4px 0 0 !important;
  padding: 0;
}
.header-top > .-inner .search label.word .s {
  width: 130px;
  height: 16px;
  color: #333;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  background: transparent !important;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.header-top > .-inner .search button {
  display: block;
  height: 100%;
  width: 30px;
  background: none;
  border: none;
  margin: 0 auto;
  padding: 0;
  opacity: 1;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #ccc;
}
.header-top > .-inner .search button i {
  display: block;
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin: auto;
  color: #fff;
}
.header-top > .-inner .search button:active {
  opacity: 0.8;
}

.header-pc {
  border-bottom: 1px solid #ccc;
}
.header-pc > .-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  padding: 10px 0;
}
.header-pc > .-inner > .-logo h1 img {
  max-width: 180px;
  width: 100%;
}
.header-pc > .-inner > .-wrap-nav {
  display: flex;
  align-items: center;
}
.header-pc > .-inner > .-wrap-nav > .-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header-pc > .-inner > .-wrap-nav > .-list > .-item {
  position: relative;
  list-style-type: none;
  padding: 0 20px 0 0;
  margin: 0;
}
.header-pc > .-inner > .-wrap-nav > .-list > .-item a {
  font-weight: bold;
  color: #333;
  letter-spacing: 0.05em;
}
.header-pc > .-inner > .-wrap-nav > .-list > .-item:after {
  content: "";
  position: absolute;
  background-image: url(../img/base/icon/i_arrow_right.svg);
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  top: 7px;
  right: 0;
  transition-duration: 200ms;
}
.header-pc > .-inner > .-wrap-nav > .-list > .-item:not(:last-child) {
  margin-right: 25px;
}
.header-pc > .-inner > .-wrap-nav > .-list > .-item:hover:after {
  right: -3px;
}
.header-pc > .-inner > .-wrap-nav > .-btn-contact {
  margin-left: 30px;
}
.header-pc > .-inner > .-wrap-nav > .-btn-contact img {
  width: 180px;
}

/*------------------------------------
フッター
------------------------------------*/
.footer-btm {
  background-color: #ebf5f7;
}
.footer-btm > .-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 820px;
  margin: 0 auto;
  padding: 20px 0;
}
.footer-btm > .-inner > .-logo {
  margin-right: 60px;
}
.footer-btm > .-inner > .-logo img {
  width: 100%;
  max-width: 180px;
}
.footer-btm > .-inner > .-info h2 {
  color: #00729a;
  font-weight: bold;
  font-size: 22px;
  margin: 0 0 5px;
}
.footer-btm > .-inner > .-info .-address {
  color: #00729a;
  margin: 0;
  font-size: 14px;
}
@media (max-width: 768px) {
  .footer-btm > .-inner {
    width: 100%;
    padding: 25px 15px;
    display: block;
    text-align: center;
  }
  .footer-btm > .-inner > .-logo {
    margin: 0;
  }
  .footer-btm > .-inner > .-logo img {
    max-width: 150px;
    margin-bottom: 15px;
  }
  .footer-btm > .-inner > .-info h2 {
    font-size: 18px;
  }
}

.copyright {
  background-color: #1a9acb;
}
.copyright > .-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1080px;
  margin: 0 auto;
  padding: 10px 0;
}
.copyright > .-inner .-wrap-nav {
  display: flex;
  align-items: center;
}
.copyright > .-inner .-wrap-nav > .-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 5px;
}
.copyright > .-inner .-wrap-nav > .-list > .-item {
  position: relative;
  list-style-type: none;
  padding: 0 20px 0 0;
  margin: 0;
}
.copyright > .-inner .-wrap-nav > .-list > .-item a {
  color: #fff;
  letter-spacing: 0.05em;
}
.copyright > .-inner .-wrap-nav > .-list > .-item:after {
  content: "";
  position: absolute;
  background-image: url(../img/base/icon/i_arrow_right_wh.svg);
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  top: 4px;
  right: 0;
  transition-duration: 200ms;
}
.copyright > .-inner .-wrap-nav > .-list > .-item:not(:last-child) {
  margin-right: 25px;
}
.copyright > .-inner .-wrap-nav > .-list > .-item:hover:after {
  right: -3px;
}
@media (max-width: 768px) {
  .copyright > .-inner {
    width: 100%;
    padding: 0;
  }
  .copyright > .-inner .-wrap-nav {
    display: none;
  }
}

.js-tab-ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  margin: 50px 0 0;
}
.js-tab-ul li {
  width: 25%;
  background-color: #eee;
  text-align: center;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 10px 5px;
  border-radius: 10px 10px 0 0;
  margin: 0;
  box-sizing: border-box;
  cursor: pointer;
}
.js-tab-ul li.current {
  background-color: #00a5e0;
  color: #fff;
  font-weight: bold;
}
.js-tab-ul li:last-child {
  border-right: 1px solid #ccc;
}

.js-tab-wrapper {
  border: 1px solid #ccc;
  border-top: 0;
  padding: 30px 15px;
}
.js-tab-wrapper > .-inner .js-tab-target {
  display: none;
}

.c-box-anshin-support {
  border: 8px solid #f2c336;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  padding: 20px 40px;
}
.c-box-anshin-support:before {
  content: url(../img/main/cable-maruwakari/ribbon.png);
  position: absolute;
  top: 0;
  left: 30px;
}
.c-box-anshin-support > .-left {
  text-align: center;
}
.c-box-anshin-support > .-right > .-inner > .-title {
  color: #00a5e0;
  font-weight: bold;
  font-size: 19px;
  line-height: 1.6;
  margin: 0 0 15px;
}
.c-box-anshin-support > .-right > .-inner > .-text {
  font-size: 14px;
  line-height: 1.8;
  margin: 0;
}
.c-box-anshin-support > .-right > .-inner > ul.-list {
  padding: 0;
  list-style-type: disc;
  margin: 0;
}
.c-box-anshin-support > .-right > .-inner > ul.-list > li {
  display: inline-block;
  line-height: 2;
  margin: 0 15px 0 0;
}
.c-box-anshin-support > .-right > .-inner > ul.-list > li:before {
  content: "・";
}
@media (min-width: 768px) {
  .c-box-anshin-support {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 30px;
  }
  .c-box-anshin-support > .-left {
    width: 20%;
  }
  .c-box-anshin-support > .-right {
    width: 75%;
  }
}
@media (max-width: 767px) {
  .c-box-anshin-support > .-left {
    margin-bottom: 30px;
  }
}

.c-attention {
  text-align: center;
  color: red;
  font-size: 46px;
  font-weight: bold;
  margin: 30px 0;
}
@media (max-width: 768px) {
  .c-attention {
    font-size: 30px;
  }
}

.c-box {
  padding: 60px;
  border: 4px solid #ccc;
  border-radius: 10px;
  background-color: #fbfeff;
}
@media (max-width: 767px) {
  .c-box {
    padding: 30px 20px;
  }
}

.p-title.-border-y {
  border-top: 2px solid #00a2e6;
  border-bottom: 2px solid #00a2e6;
  padding: 10px;
  font-size: 1.4em;
  font-weight: 600;
}
.p-title.-bar {
  background-color: #00a5e0;
  color: #333;
  padding: 10px 15px 8px;
  border-radius: 4px;
}
.p-title.-bar.-blue {
  background-color: #00a5e0;
  color: #fff;
}
.p-title.-flag {
  padding: 10px 15px 8px;
  border-radius: 4px;
  background-color: #f8f8f8;
}
.p-title.-flag.-blue {
  border-left: 6px solid #00a5e0;
}
.p-title.-h2 {
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .p-title.-h2 {
    font-size: 22px;
  }
}
.p-title.-area {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 30px;
  padding: 20px 5px;
  line-height: 1.3;
  background: #c11920;
  color: #fff;
  border-radius: 50px;
}
.p-title.-area > .-sm {
  font-size: 0.7em;
}
.p-title.-merit {
  position: relative;
  display: block;
  width: 90%;
  margin: 0 auto 30px;
  padding: 10px 0;
  background: #fdfc00;
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
}
.p-title.-merit > span {
  color: #00a0e4;
}
.p-title.-merit:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  bottom: -10px;
  transform: translate(-50%, 0);
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #fdfd00 transparent transparent transparent;
}
.p-title.-arrow {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
}
.p-title.-plain {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
}
.p-title.-plain:after {
  content: none;
}

.p-box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 15px;
  font-weight: bold;
}
.p-box.-blue {
  border-color: #00a5e0;
  color: #00a5e0;
}
.p-btn.-square {
  position: relative;
  display: block;
  font-weight: bold;
  padding: 0.25em 0.5em;
  text-decoration: none;
  color: #FFF;
  background: #00bcd4;
  transition: 0.3s;
  padding: 20px;
  border-radius: 8px;
  margin: 30px auto;
  width: 90%;
  font-size: 2rem;
  border: none;
}
.p-btn.-blue {
  background-color: #00a5e0;
}
.p-btn.-green {
  background-color: #00bcd4;
}
.p-btn:hover {
  opacity: 0.7;
}
.p-btn.-default {
  background-color: #00a5e0;
  display: inline-block;
  height: 40px;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  position: relative;
}
.p-btn.-default.-icon {
  padding-left: 35px;
}
.p-btn.-default.-icon:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 13px;
  height: 15px;
  width: 10px;
  background-image: url(../img/base/icon/i_arrow_right_wh.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.p-radio.-inline {
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #b6b6b6;
}
.p-radio.-inline div {
  position: relative;
  flex: 1;
}
.p-radio.-inline input {
  width: 100%;
  height: 60px;
  opacity: 0;
}
.p-radio.-inline label {
  position: absolute;
  top: 0;
  left: 0;
  color: #b6b6b6;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  border-right: 1px solid #b6b6b6;
}
.p-radio.-inline div:last-child label {
  border-right: 0;
}
.p-radio.-inline input:checked + label {
  background: #d81b60;
  font-weight: 500;
  color: #fff;
}
@media (max-width: 767px) {
  .p-radio.-inline input {
    height: 40px;
  }
  .p-radio.-inline label {
    font-size: 1.5rem;
  }
}

.p-table {
  font-size: 2rem;
}
.p-table.-arrow {
  width: 100%;
  border-collapse: collapse;
}
.p-table.-arrow tr {
  border-bottom: solid 2px white;
}
.p-table.-arrow tr:last-child {
  border-bottom: none;
}
.p-table.-arrow tr th {
  position: relative;
  text-align: left;
  width: 40%;
  background-color: #52c2d0;
  color: white;
  text-align: center;
  padding: 10px 0;
}
.p-table.-arrow tr th:after {
  display: block;
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  top: calc(50% - 10px);
  right: -10px;
  border-left: 10px solid #52c2d0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.p-table.-arrow tr td {
  text-align: left;
  width: 60%;
  text-align: center;
  background-color: #eee;
  padding: 10px 0;
}
.p-table.-arrow.-green tr th {
  background-color: #00bcd4;
}
.p-table.-arrow.-green tr th:after {
  border-left-color: #00bcd4;
}
@media (max-width: 767px) {
  .p-table {
    font-size: 1.5rem;
  }
}

.p-title-flex {
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.p-title-flex > * {
  margin-bottom: 0;
}
.p-title-flex > .-right {
  margin-left: auto;
}

.p-faq {
  margin-bottom: 30px;
  padding-left: 35px;
}
.p-faq li {
  list-style-type: none;
  position: relative;
}
.p-faq li:before {
  content: "Q.";
  position: absolute;
  left: -30px;
  font-size: 20px;
  font-weight: bold;
  color: #00729a;
}

.p-tv-list {
  margin-bottom: 20px;
}
.p-tv-list > .-title {
  display: block;
  color: #fff;
  margin-bottom: 5px;
  font-weight: bold;
  padding: 8px 20px;
  font-size: 20px;
}
.p-tv-list > .-box {
  background-color: #eee;
  padding: 10px 10px 0;
}
.p-tv-list > .-box .-list {
  display: flex;
  flex-wrap: wrap;
}
.p-tv-list > .-box .-list > span {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 15px;
  color: #222;
  font-weight: bold;
  font-size: 22px;
}
.p-tv-list > .-box > .-attention {
  margin-bottom: 10px;
}
.p-tv-list > .-box > .-attention > .-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.p-tv-list > .-box > .-attention > .-title > img {
  margin-right: 5px;
}
.p-tv-list > .-box > .-attention > .-sm {
  font-size: 14px;
  margin-bottom: 5px;
}
.p-tv-list > .-box > .-attention > .-inner > img {
  margin-bottom: 5px;
}
.p-tv-list.-pink > .-title {
  background-color: #f75f6f;
}
.p-tv-list.-pink > .-box .-list > span {
  background-color: #ffadb5;
}
.p-tv-list.-green > .-title {
  background-color: #16902e;
}
.p-tv-list.-green > .-box .-list > span {
  background-color: #c1db81;
}
.p-tv-list.-yellow > .-title {
  background-color: #cab400;
}
.p-tv-list.-yellow > .-box .-list > span {
  background-color: #fff33f;
}

.p-flow .table {
  width: 100%;
}
.p-flow .table .left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 180px;
  height: 150px;
  background: #888;
}
.p-flow .table .left:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 75px 0 75px 20px;
  border-color: transparent transparent transparent #888;
}
.p-flow .table .left span {
  color: #fff;
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
}
.p-flow .table .right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 180px);
  height: 150px;
  margin: 0;
  padding: 0;
  background-size: 5px 5px;
  background-color: #fff;
  background-image: linear-gradient(-45deg, #f6f5f2 25%, transparent 25%, transparent 50%, #f6f5f2 50%, #f6f5f2 75%, transparent 75%, transparent);
}
.p-flow .table .right .inbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 25px;
  width: calc(100% - 30px);
  height: 130px;
  background: #fff;
  margin: auto;
}
.p-flow .table .right .inbox p.-title {
  font-size: 1.4em !important;
  font-weight: bold;
  margin: auto 0 10px;
}
.p-flow .table .right .inbox .flex-box {
  width: 100%;
  justify-content: flex-end;
}
.p-flow .table .right .inbox p.big {
  font-size: 2.5em;
  line-height: 1;
}
.p-flow .table .right .inbox p.big small {
  font-size: 0.65em;
}
.p-flow .table .right .inbox p.label {
  background: #7abb43;
  padding: 2px 32px;
  color: #fff;
  font-size: 1.1em;
  text-align: center;
  margin-right: 20px;
}
.p-flow.orenge .table .left {
  background: #ff851e;
}
.p-flow.orenge .table .left:after {
  border-color: transparent transparent transparent #ff851e;
}
.p-flow.orenge .table .right .inbox p.big {
  color: #ff851e;
}
.p-flow.green .table .left {
  background: #7abb43;
}
.p-flow.green .table .left:after {
  border-color: transparent transparent transparent #7abb43;
}
.p-flow.green .table .right .inbox p.big {
  color: #7abb43;
}
.p-flow .notice {
  font-size: 1.4em;
}
.p-flow .notice i {
  color: #ff851e;
}
.p-flow .notice span {
  color: #f00;
}
@media (max-width: 767px) {
  .p-flow .table {
    width: 100%;
    display: block;
  }
  .p-flow .table .left {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
    background: #888;
    padding: 10px;
  }
  .p-flow .table .left:after {
    content: "";
    display: block;
    position: absolute;
    top: auto;
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, 0);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #888 transparent transparent transparent;
  }
  .p-flow .table .left span {
    color: #fff;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
  }
  .p-flow .table .right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    background-size: 5px 5px;
    background-color: #fff;
    background-image: linear-gradient(-45deg, #f6f5f2 25%, transparent 25%, transparent 50%, #f6f5f2 50%, #f6f5f2 75%, transparent 75%, transparent);
  }
  .p-flow .table .right .inbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
    padding: 20px 0 0;
    width: 100%;
    height: auto;
    background: #fff;
    margin: auto;
  }
  .p-flow .table .right .inbox p {
    text-align: center;
    font-size: 1em;
    margin: 0 auto;
  }
  .p-flow .table .right .inbox p.-title {
    display: block;
    width: 100%;
  }
  .p-flow .table .right .inbox .flex-box {
    display: block;
  }
  .p-flow .table .right .inbox p.big {
    font-size: 2em;
    line-height: 1;
    margin-top: 20px;
  }
  .p-flow .table .right .inbox p.big small {
    font-size: 0.5em;
  }
  .p-flow .table .right .inbox p.label {
    display: block;
    font-size: 1em;
    width: 80%;
    margin: 20px auto;
    padding: 2px;
  }
  .p-flow.orenge .table .left:after {
    border-color: #ff851e transparent transparent transparent;
  }
  .p-flow.green .table .left:after {
    border-color: #7abb43 transparent transparent transparent;
  }
  .p-flow .notice {
    font-size: 1em;
    text-align: center;
  }
}

.p-list-option-channel {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 800px;
}
.p-list-option-channel > * {
  width: 18%;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .p-list-option-channel > * {
    width: 31%;
  }
}

.p-article__header {
  border-bottom: 1px dotted #DEDEDE;
  margin-bottom: 40px;
}
.p-article__header > .-back {
  text-decoration: underline;
  margin-bottom: 40px;
  display: block;
}
.p-article__header > .-meta {
  margin-bottom: 20px;
}
.p-article__header > .-meta > .-date {
  display: inline-block;
  font-size: 14px;
  color: #808080;
  margin-right: 30px;
}
.p-article__header > .-meta > .-cat {
  width: 10em;
  font-size: 13px;
  background-color: #FBF6EF;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.p-article__header > .-title {
  line-height: 1.5;
  font-size: 30px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .p-article__header {
    margin-bottom: 30px;
  }
  .p-article__header > .-back {
    margin-bottom: 30px;
  }
  .p-article__header > .-meta > .-date {
    margin-right: 15px;
  }
  .p-article__header > .-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.p-article__content {
  line-height: 2;
  font-size: 18px;
}
.p-article__content > *:first-child {
  margin-top: 0;
}
.p-article__content h1, .p-article__content h2, .p-article__content h3, .p-article__content h4, .p-article__content h5, .p-article__content h6 {
  text-align: left;
}
.p-article__content h2, .p-article__content h3, .p-article__content h4, .p-article__content h5, .p-article__content h6 {
  line-height: 1.6;
}
.p-article__content h3 {
  font-weight: bold;
  font-size: 1.6em;
  margin: 2em 0 1.5em;
}
.p-article__content h4 {
  font-weight: bold;
  font-size: 20px;
  margin: 80px 0 40px;
}
@media (max-width: 768px) {
  .p-article__content h4 {
    font-size: 15px;
    margin: 50px 0 30px;
  }
}
.p-article__content h5 {
  font-weight: bold;
  font-size: 1.4em;
  margin: 2em 0 1em;
}
.p-article__content p {
  margin-bottom: 2em;
  line-height: 2;
}
.p-article__content a {
  text-decoration: underline;
}
.p-article__content img {
  max-width: 100%;
  width: inherit;
  height: auto;
  margin-bottom: 15px;
}
.p-article__content .aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.p-article__content ol, .p-article__content ul {
  padding-left: 35px;
}
.p-article__content ol li, .p-article__content ul li {
  padding-left: 0.5em;
  margin-bottom: 1.2em;
}
.p-article__content ol li::marker, .p-article__content ul li::marker {
  font-size: 1.2em;
  font-weight: 600;
  color: #00A0E4;
}
@media (max-width: 768px) {
  .p-article__content {
    font-size: h2;
    font-size-font-size: 1.6em;
  }
  .p-article__content h3 {
    font-size: 1.4em;
  }
  .p-article__content h4 {
    font-size: 1.2em;
  }
  .p-article__content h5 {
    font-size: 1.1em;
  }
  .p-article__content .entry-thumbnail {
    margin-bottom: 16px;
  }
}

/* au携帯をお使いならさらにおトク！ */
.au-smart .sec-container {
  padding: 80px 0;
}

.au-smart h2 {
  margin-bottom: 40px;
}

.au-smart h2 img {
  display: block;
  width: 812px;
  height: 100%;
  margin: 0 auto;
}

.au-smart .ausmartvalue {
  display: block;
  margin: 40px auto;
  width: 502px;
  height: 100%;
}

.au-smart .telandsp {
  display: block;
  margin: 40px auto;
  width: 718px;
  height: 100%;
}

.au-smart .commentary {
  position: relative;
  display: block;
  width: 100%;
  margin: 30px 0 0;
  padding: 20px 0;
  background: #fdfc00;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.au-smart .commentary:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: -20px;
  transform: translate(-50%, 0);
  border-style: solid;
  border-width: 0 15px 20px 15px;
  border-color: transparent transparent #fdfd00 transparent;
}

.au-smart .commentary p {
  margin: 0;
  color: #000055;
}

.au-smart .commentary p span {
  color: #e6292c;
}

.au-smart .einen {
  display: block;
  margin: 80px auto 40px;
  width: 800px;
  height: 100%;
}

.au-smart .box .top {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5c24;
  height: 100px;
}

.au-smart .box .top h4 {
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  margin: 0;
  background-color: transparent !important;
}

.au-smart .box .top h4.small {
  font-size: 0.8em;
}

.au-smart .box .bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #ff5c24;
  border-top: none;
  background: #fff;
  height: 120px;
}

.au-smart .box .bottom p {
  margin: 0;
  font-weight: bold;
  font-size: 1.3em;
  line-height: 1.25;
}

.au-smart .box .bottom p span {
  display: block;
  font-size: 1.5em;
  color: #ff5c24;
}

.au-smart .set {
  margin-top: 80px;
}

.au-smart .set h3 {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin: 0;
}

.au-smart .set .under_arrow {
  display: block;
  margin: 5px auto 30px;
  width: 514px;
  height: 100%;
}

.au-smart .set .middle {
  text-align: center;
  font-size: 1.3em;
  font-weight: bold;
  margin: 0 0 60px;
}

.au-smart .set .waribiki {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  margin: 0 auto;
}

.au-smart .set .waribiki .left {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 120px;
  background: #ff5c24;
}

.au-smart .set .waribiki .left:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -30px;
  top: 50%;
  transform: translate(0, -50%);
  border-style: solid;
  border-width: 60px 0 60px 30px;
  border-color: transparent transparent transparent #ff5c24;
}

.au-smart .set .waribiki .left p {
  font-size: 1.6em !important;
  color: #fff;
  line-height: 1.5 !important;
  margin: 0;
}

.au-smart .set .waribiki .right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  height: 100px;
}

.au-smart .set .waribiki .right p {
  font-size: 2em !important;
  line-height: 1 !important;
  margin: 5px 0;
}

.au-smart .set .waribiki .right p span {
  font-size: 1.5em;
  color: #ff5c24;
}

.au-smart .set .waribiki .right p.notice {
  font-size: 1em !important;
}

.au-smart .comments {
  width: 800px;
  margin: 40px auto 0;
}

.au-smart .comments p {
  font-size: 1em;
  line-height: 1.75;
  margin: 20px 0;
}

.au-smart .comments p .top {
  display: inline-block;
  margin-right: 5px;
  padding: 5px 10px;
  background: #666;
  color: #fff;
}

@media (max-width: 767px) {
  /* au携帯をお使いならさらにおトク！ */
  .au-smart .sec-container {
    padding: 40px 0;
  }

  .au-smart h2 {
    margin-bottom: 20px;
  }

  .au-smart h2 img {
    width: 100%;
  }

  .au-smart .ausmartvalue {
    margin: 40px auto;
    width: 251px;
  }

  .au-smart .telandsp {
    margin: 40px auto;
    width: 359px;
  }

  .au-smart .commentary {
    position: relative;
    display: block;
    width: 100%;
    margin: 20px 0 0;
    padding: 10px 5px;
    background: #fdfc00;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
  }

  .au-smart .einen {
    margin: 40px auto 20px;
    width: 100%;
  }

  .au-smart .box {
    margin-bottom: 10px;
  }

  .au-smart .box .top {
    height: auto;
    padding: 10px 0;
  }

  .au-smart .box .top h4 {
    font-size: 1.25em;
  }

  .au-smart .box .top h4.small {
    font-size: 0.8em;
  }

  .au-smart .box .bottom {
    border: 2px solid #ff5c24;
    height: auto;
    padding: 10px 0;
  }

  .au-smart .box .bottom p {
    font-size: 1.2em;
    line-height: 1.25;
  }

  .au-smart .box .bottom p span {
    font-size: 1.4em;
  }

  .au-smart .set {
    margin-top: 40px;
  }

  .au-smart .set h3 {
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    margin: 0;
  }

  .au-smart .set .under_arrow {
    display: block;
    margin: 5px auto 20px;
    width: 207px;
    height: 100%;
  }

  .au-smart .set .middle {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0 30px;
  }

  .au-smart .set .waribiki {
    display: block;
    width: 100%;
  }

  .au-smart .set .waribiki .left {
    width: 100%;
    height: 80px;
    margin-bottom: 30px;
  }

  .au-smart .set .waribiki .left:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 100%;
    bottom: -10px;
    transform: translate(-50%, 0);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #ff5c24 transparent transparent transparent;
  }

  .au-smart .set .waribiki .left p {
    font-size: 1.3em;
    line-height: 1.5;
    text-align: center;
  }

  .au-smart .set .waribiki .right {
    display: block;
    width: auto;
    height: auto;
  }

  .au-smart .set .waribiki .right p {
    font-size: 1.75em !important;
    margin: 20px 0 0;
    text-align: center;
  }

  .au-smart .set .waribiki .right p span {
    font-size: 1.25em;
  }

  .au-smart .set .waribiki .right p.notice {
    line-height: 1.25;
  }

  .au-smart .comments {
    width: 100%;
    margin: 20px auto 0;
  }

  .au-smart .comments p {
    font-size: 0.9em;
    line-height: 1.75;
    margin: 20px 0;
  }

  .au-smart .comments p .top {
    display: inline-block;
    margin-right: 5px;
    padding: 5px 10px;
    background: #666;
    color: #fff;
  }
}
.mw_wp_form .btn.btn-primary {
  position: relative;
  display: block;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.25em 0.5em;
  text-decoration: none;
  color: #FFF;
  background: #00bcd4;
  transition: 0.3s;
  padding: 20px;
  border-radius: 8px;
  margin: 30px auto;
  width: 90%;
  border: none;
}
.mw_wp_form .btn.btn-primary:hover {
  opacity: 0.7;
}

/*------------------------------------
トップページ
------------------------------------*/
.bg-top {
  background-image: url(../img/main/front-page/bg_top.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.news-danger {
  padding: 15px;
  background-image: url(../img/main/front-page/bg_news_danger.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.news-danger > .-list {
  background-color: #fff;
  margin: 0;
  border: 1px solid #ccc;
  padding: 0 10px;
}
.news-danger > .-list > .-item {
  list-style-type: none;
  padding: 18px 10px;
  margin: 0;
}
.news-danger > .-list > .-item a {
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: #333;
}
.news-danger > .-list > .-item a .-date {
  font-size: 14px;
  width: 10em;
}
.news-danger > .-list > .-item a .c-cat {
  margin-right: 30px;
}
.news-danger > .-list > .-item a .-title {
  font-size: 16px;
}
.news-danger > .-list > .-item a:after {
  content: "";
  position: absolute;
  background-image: url(../img/base/icon/i_arrow_right_gr.svg);
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  top: 7px;
  right: 0;
  transition-duration: 200ms;
}
.news-danger > .-list > .-item a:not(:last-child) {
  margin-right: 25px;
}
.news-danger > .-list > .-item a:hover:after {
  right: -3px;
}
.news-danger > .-list > .-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
@media (max-width: 767px) {
  .news-danger > .-list > .-item a {
    flex-wrap: wrap;
  }
  .news-danger > .-list > .-item a .c-cat {
    margin-right: 0;
  }
  .news-danger > .-list > .-item a .-title {
    margin-top: 10px;
  }
  .news-danger > .-list > .-item a:after {
    top: 40%;
    right: -12px;
  }
}

.area-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 50px;
}
.area-top > .-item > .-title-en {
  text-align: center;
  font-size: 22px;
  padding: 0;
  margin: 0;
  background: transparent;
  line-height: 1.2;
}
.area-top > .-item > .-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 12px;
  border-bottom: none;
  padding: 0;
}
.area-top > .-item > .-title:after {
  content: none;
}
.area-top > .-item a {
  display: block;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .area-top > .-col {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .area-top > .-item {
    margin-bottom: 40px;
  }
  .area-top > .-item > .-title {
    font-size: 22px;
  }
}

.area {
  padding: 50px 0 30px;
}

.area-service > .-btn-list > .-item {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .area-service > .-btn-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .area-service > .-btn-list > .-item {
    width: 32%;
  }
}

.area-news {
  margin: 0;
}
.area-news .-head-title {
  font-weight: bold;
  color: #00a5e0;
  margin-bottom: 10px;
  font-size: 22px;
}
.area-news .-box {
  background-color: #fff;
  padding: 20px 15px;
  margin: 0 0 20px;
}
.area-news .-box .-list {
  padding: 0;
  margin: 0 0 20px;
}
.area-news .-box .-list > .-item {
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
  line-height: 1.6;
  border-bottom: 1px solid #ccc;
}
.area-news .-box .-list > .-item a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  color: #333;
}
.area-news .-box .-list > .-item a .-date {
  font-size: 14px;
  width: 20%;
}
.area-news .-box .-list > .-item a .c-cat {
  width: 25%;
  font-size: 12px;
}
.area-news .-box .-list > .-item a .c-cat > .-inner {
  height: 24px;
  line-height: 2;
}
.area-news .-box .-list > .-item a .-title {
  width: 55%;
  font-size: 14px;
}
.area-news .-box .-list > .-item a .-title.-lg {
  width: 80%;
}
@media (max-width: 767px) {
  .area-news .-head-title {
    text-align: center;
  }
  .area-news .-box {
    margin-bottom: 40px;
  }
  .area-news .-box .-list > .-item a {
    flex-wrap: wrap;
  }
  .area-news .-box .-list > .-item a .-date {
    width: 7em;
  }
  .area-news .-box .-list > .-item a .c-cat {
    margin-right: 0;
  }
  .area-news .-box .-list > .-item a .-title {
    margin-top: 10px;
    width: 100%;
  }
  .area-news .-box .-list > .-item a:after {
    top: 40%;
    right: -12px;
  }
}

.area-more-information .-content > .-wrap-tel {
  margin-bottom: 8px;
}
.area-more-information .-content > .-wrap-tel > .-title {
  font-weight: bold;
  font-size: 28px;
  padding-top: 10px;
}
.area-more-information .-content > .-wrap-tel > .-title > .-i {
  width: 28px;
  margin-right: 4px;
  position: relative;
  top: -1px;
}
.area-more-information .-content > .-wrap-tel > .-tel {
  color: #00a5e0;
  font-size: 60px;
  font-weight: bold;
}
.area-more-information .-content > .-wrap-tel > .-sm {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
.area-more-information .-content > .-wrap-btn {
  display: flex;
}
.area-more-information .-content > .-wrap-btn > .-item > .-desc {
  text-align: justify;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .area-more-information {
    padding: 60px 0 50px;
  }
  .area-more-information .area_title {
    margin-bottom: 40px;
  }
  .area-more-information .-content {
    display: flex;
    justify-content: space-between;
  }
  .area-more-information .-content > .-wrap-tel {
    width: 40%;
    border-left: 2px solid #00729a;
    padding-left: 15px;
  }
  .area-more-information .-content > .-wrap-btn {
    width: 62%;
    justify-content: space-between;
  }
  .area-more-information .-content > .-wrap-btn > .-item {
    width: 32%;
  }
}
@media (max-width: 767px) {
  .area-more-information .-content > .-wrap-tel {
    text-align: center;
    margin-bottom: 40px;
  }
  .area-more-information .-content > .-wrap-tel > .-tel {
    text-align: center;
    font-size: 52px;
  }
  .area-more-information .-content > .-wrap-btn {
    display: block;
  }
  .area-more-information .-content > .-wrap-btn > .-item {
    width: 220px;
    margin: 0 auto 20px;
  }
  .area-more-information .-content > .-wrap-btn > .-item > .-desc {
    text-align: center;
  }
}

.area-sponsored .-list {
  padding: 0;
}
.area-sponsored .-list > .-item {
  display: inline-block;
  margin-bottom: 10px;
}
.area-sponsored .-list > .-item img {
  display: block;
}
@media (min-width: 768px) {
  .area-sponsored .-list > .-item {
    width: 23%;
    margin-right: 1%;
  }
}
@media (max-width: 767px) {
  .area-sponsored .-list > .-item {
    width: 48%;
    margin-right: 1%;
  }
}

/*------------------------------------
サービスページ
------------------------------------*/
.page-service img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .page-service.internet-hikari .wrap-title-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0;
  }
  .page-service.internet-hikari .wrap-title-top > .-left {
    display: flex;
    align-items: flex-end;
  }
  .page-service.internet-hikari .wrap-title-top > .-left > .-ttl01 {
    width: 200px;
    margin-right: 20px;
  }
  .page-service.internet-hikari .wrap-title-top > .-left > .-ttl02 {
    width: 350px;
  }
  .page-service.internet-hikari .wrap-title-top > .-right > .-ttl-au {
    width: 300px;
  }
}
@media (max-width: 767px) {
  .page-service.internet-hikari .wrap-title-top {
    margin: 20px 0;
    text-align: center;
  }
  .page-service.internet-hikari .wrap-title-top > .-left > .-ttl01 {
    max-width: 200px;
    margin-bottom: 15px;
  }
  .page-service.internet-hikari .wrap-title-top > .-left > .-ttl02 {
    max-width: 300px;
    margin-bottom: 20px;
  }
  .page-service.internet-hikari .wrap-title-top > .-right {
    text-align: right;
  }
  .page-service.internet-hikari .wrap-title-top > .-right > .-ttl-au {
    max-width: 260px;
  }
}
.page-service.internet-hikari .table-price {
  min-width: 500px;
}
.page-service .responsive-flame {
  max-width: 100%;
  overflow-x: scroll;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.page-service .box-smartvalue {
  border: 6px solid #EB6100;
  border-radius: 5px 5px 0 0;
  margin-bottom: 50px;
}
.page-service .box-smartvalue > .-title {
  padding: 15px 10px;
  background-color: #EB6100;
  text-align: center;
}
.page-service .box-smartvalue > .-title > span {
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
  padding: 2px 4px;
}
.page-service .box-smartvalue > .-inner {
  padding: 30px;
}
@media (min-width: 768px) {
  .page-service .box-smartvalue > .-title {
    position: relative;
  }
  .page-service .box-smartvalue > .-title img {
    max-width: 330px;
  }
  .page-service .box-smartvalue > .-title > span {
    position: absolute;
    right: 10px;
    bottom: 15px;
  }
  .page-service .box-smartvalue > .-inner > .-wrap-copy {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .page-service .box-smartvalue > .-inner > .-wrap-copy > .-left img {
    width: 470px;
  }
  .page-service .box-smartvalue > .-inner > .-wrap-copy > .-right img {
    width: 440px;
  }
  .page-service .box-smartvalue > .-inner p {
    font-size: 16px;
  }
  .page-service .box-smartvalue > .-inner > .-desc {
    display: flex;
  }
  .page-service .box-smartvalue > .-inner > .-desc h4 {
    font-size: 16px;
    width: 22em;
    display: inline-table;
    padding: 10px 10px 8px;
    text-align: center;
  }
  .page-service .box-smartvalue > .-inner > .-desc > .-inner {
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  .page-service .box-smartvalue > .-title {
    padding-bottom: 40px;
  }
  .page-service .box-smartvalue > .-title > span {
    float: right;
    margin-top: 10px;
  }
  .page-service .box-smartvalue > .-inner {
    padding: 30px 10px;
  }
  .page-service .box-smartvalue > .-inner .-wrap-copy {
    text-align: center;
    margin-bottom: 30px;
  }
  .page-service .box-smartvalue > .-inner .-wrap-copy > .-left img {
    max-width: 300px;
    width: 60%;
    margin-bottom: 20px;
  }
  .page-service .box-smartvalue > .-inner .-wrap-copy > .-right img {
    max-width: 600px;
    width: 95%;
  }
}
.page-service .attention h6 {
  font-size: 16px;
  margin: 1em 0 0;
}
.page-service .attention ul {
  list-style-type: none;
  padding-left: 10px;
  margin-top: 0;
}

.page-cable-maruwakari > .-area {
  padding: 60px 0;
}
.page-cable-maruwakari > .-area .-attention {
  font-size: 12px;
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.8;
}
.page-cable-maruwakari > .-area .-attention > .-inner {
  background-color: #daebee;
}
.page-cable-maruwakari > .-area-movie {
  margin-top: 30px;
  margin-bottom: 60px;
}
.page-cable-maruwakari > .-area-about {
  background-color: #eee;
  position: relative;
}
.page-cable-maruwakari > .-area-about:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 56%;
  height: 100%;
  background-image: url(../img/main/cable-maruwakari/bg_area_about.jpg);
  background-size: cover;
  background-position: center;
}
.page-cable-maruwakari > .-area-about .-box {
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 50px 40px;
  margin: 40px 0;
}
.page-cable-maruwakari > .-area-about .-box p {
  margin: 0 0 2em;
  font-size: 15px;
  line-height: 1.6;
}
.page-cable-maruwakari > .-area-about .-box p:last-child {
  margin: 0;
}
@media (max-width: 767px) {
  .page-cable-maruwakari > .-area-about:before {
    width: 100%;
    height: 360px;
  }
  .page-cable-maruwakari > .-area-about .-box {
    margin-top: 260px;
    padding: 30px 20px;
  }
}
.page-cable-maruwakari > .-area-area {
  background-color: #ebf5f7;
  padding: 60px 0;
}
.page-cable-maruwakari > .-area-service .-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-cable-maruwakari > .-area-service .-list > .c-panel-service {
  width: 32%;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .page-cable-maruwakari > .-area-service .-list {
    justify-content: center;
  }
  .page-cable-maruwakari > .-area-service .-list > .c-panel-service {
    width: 100%;
    max-width: 280px;
  }
}
.page-cable-maruwakari > .-area-support {
  background-color: #ebf5f7;
}
.page-cable-maruwakari > .-area-support .-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}
.page-cable-maruwakari > .-area-support .-list > .c-panel-support {
  width: 32%;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .page-cable-maruwakari > .-area-support .-list {
    justify-content: center;
  }
  .page-cable-maruwakari > .-area-support .-list > .c-panel-support {
    width: 100%;
    max-width: 280px;
  }
}
.page-cable-maruwakari > .-area-voice .slider-voice {
  padding-bottom: 50px;
}
.page-cable-maruwakari > .-area-voice .slider-voice > .swiper-pagination {
  bottom: 0;
}
.page-cable-maruwakari > .-area-voice .slider-voice > .swiper-button-prev, .page-cable-maruwakari > .-area-voice .slider-voice > .swiper-button-next {
  top: 40%;
}
.page-price-simulation .js-simulation-wrap {
  margin-top: 50px;
}
.page-price-simulation .js-simulation-wrap .-group {
  margin-bottom: 60px;
}
.page-price-simulation .js-simulation-wrap .-group input {
  margin-right: 5px;
  cursor: pointer;
}
.page-price-simulation .js-simulation-wrap .-group input:not(:first-of-type) {
  margin-left: 30px;
}
.page-price-simulation .-result {
  max-width: 660px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .page-price-simulation .js-simulation-wrap .-group {
    margin-bottom: 40px;
  }
}