.js-tab-ul {
	list-style-type: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #ccc;
	margin: 50px 0 0;
	li {
		width: 25%;
		background-color: #eee;
		text-align: center;
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		padding: 10px 5px;
		border-radius: 10px 10px 0 0;
		margin: 0;
		box-sizing: border-box;
		cursor: pointer;
		&.current {
			background-color: $blue;
			color: #fff;
			font-weight: bold;
		}
		&:last-child {
			border-right: 1px solid #ccc;
		}
	}
}

.js-tab-wrapper {
	border: 1px solid #ccc;
	border-top: 0;
	padding: 30px 15px;
	>.-inner {
		// position: relative;
		.js-tab-target {
			display: none;
			// position: absolute;
			// top: 0;
			// left: 0;
			// opacity: 0;
		}
	}
}