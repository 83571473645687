/*------------------------------------
サービスページ
------------------------------------*/
.page-service {
    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    &.internet-hikari {
        .wrap-title-top {
            @media (min-width: 768px) {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin: 20px 0;
                >.-left {
                    display: flex;
                    align-items: flex-end;
                    >.-ttl01 {
                        width: 200px;
                        margin-right: 20px;
                    }
                    >.-ttl02 {
                        width: 350px;
                    }
                }
                >.-right {
                    >.-ttl-au {
                        width: 300px;
                    }
                }
            }
            @media (max-width: 767px) {
                margin: 20px 0;
                text-align: center;
                >.-left {
                    >.-ttl01 {
                        max-width: 200px;
                        margin-bottom: 15px;
                    }
                    >.-ttl02 {
                        max-width: 300px;
                        margin-bottom: 20px;
                    }
                }
                >.-right {
                    text-align: right;
                    >.-ttl-au {
                        max-width: 260px;
                    }
                }
            }
        }
        .table-price {
            min-width: 500px;
        }
    }
    .responsive-flame {
        max-width: 100%;
        overflow-x: scroll;
        padding-bottom: 15px;
        margin-bottom: 30px;
    }
    .box-smartvalue {
        border: 6px solid #EB6100;
        border-radius: 5px 5px 0 0;
        margin-bottom: 50px;
        >.-title {
            padding: 15px 10px;
            background-color: #EB6100;
            text-align: center;
            >span {
                border: 1px solid #fff;
                color: #fff;
                font-size: 12px;
                padding: 2px 4px;
            }
        }
        >.-inner {
            padding: 30px;
        }
       @media (min-width: 768px) {
            >.-title {
                position: relative;
                img {
                    max-width: 330px;
                }
                >span {
                    position: absolute;
                    right: 10px;
                    bottom: 15px;
                }
            }
            >.-inner {
                >.-wrap-copy {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    >.-left {
                        img {
                            width: 470px;
                        }
                    }
                    >.-right {
                        img {
                            width: 440px;
                        }
                    }
                }
                p {
                    font-size: 16px;

                }
                >.-desc {
                    display: flex;
                    h4 {
                        font-size: 16px;
                        width: 22em;
                        display: inline-table;
                        padding: 10px 10px 8px;
                        text-align: center;
                    }
                    >.-inner {
                        margin-left: 15px;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            >.-title {
                padding-bottom: 40px;
                >span {
                    float: right;
                    margin-top: 10px;
                }
            }
            >.-inner {
                padding: 30px 10px;
                .-wrap-copy {
                    text-align: center;
                    margin-bottom: 30px;
                    >.-left {
                        img {
                            max-width: 300px;
                            width: 60%;
                            margin-bottom: 20px;
                        }
                    }
                    >.-right {
                        img {
                            max-width: 600px;
                            width: 95%;
                        }
                    }
                }
            }
        }
    }
    .attention {
        h6 {
            font-size: 16px;
            margin: 1em 0 0;
        }
        ul {
            list-style-type: none;
            padding-left: 10px;
            margin-top: 0;
        }
    }
}