.p-title-flex {
	margin-bottom: 4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	>* {
		margin-bottom: 0;
	}
	>.-right {
		margin-left: auto;
	}
}