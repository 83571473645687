.p-article {
	&__header {
		border-bottom: 1px dotted #DEDEDE;
		margin-bottom: 40px;
		>.-back {
			text-decoration: underline;
			margin-bottom: 40px;
			display: block;
		}
		>.-meta {
			margin-bottom: 20px;
			>.-date {
				display: inline-block;
				font-size: 14px;
				color: #808080;
				margin-right: 30px;
			}
			>.-cat {
				width: 10em;
				font-size: 13px;
				background-color: #FBF6EF;
				height: 30px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
			}
		}
		>.-title {
			line-height: 1.5;
			font-size: 30px;
			margin-bottom: 30px;
		}

		@media (max-width: 768px) {
			margin-bottom: 30px;
			>.-back {
				margin-bottom: 30px;
			}
			>.-meta {
				>.-date {
					margin-right: 15px;
				}
				>.-cat {

				}
			}
			>.-title {
				font-size: 24px;
				margin-bottom: 20px;
			}
		}
	}

	&__content {
		line-height: 2.0;
		font-size: 18px;
		// margin-bottom: 120px;
		> *:first-child {
			margin-top: 0;
		}
		h1,h2,h3,h4,h5,h6 {
			text-align: left;
		}
		h2,h3,h4,h5,h6 {
			line-height: 1.6;
		}
		// h1 {
		// 	font-size: 2.4em;
		// 	margin-bottom: 1em;
		// }
		// h2 {
		// 	font-weight: bold;
		// 	font-size: 1.8em;
		// 	margin: 2em 0 1em;
		// }
		h3 {
			font-weight: bold;
			font-size: 1.6em;
			margin: 2em 0 1.5em;
		}
		h4 {
			font-weight: bold;
			font-size: 20px;
			margin: 80px 0 40px;
			@media (max-width: 768px) {
				font-size: 15px;
				margin: 50px 0 30px;
			}
		}
		h5 {
			font-weight: bold;
			font-size: 1.4em;
			margin: 2em 0 1em;
		}
		p {
			margin-bottom: 2.0em;
			line-height: 2.0;
		}
		a {
			text-decoration: underline;
		}
		img {
			max-width: 100%;
			width: inherit;
			height: auto;
			margin-bottom: 15px;
		}
		.aligncenter {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
		ol, ul {
			padding-left: 35px;
			li {
				padding-left: 0.5em;
				margin-bottom: 1.2em;
				&::marker {
					font-size: 1.2em;
					font-weight: 600;
					color: #00A0E4;
				}
			}
		}

		@media (max-width: 768px) {
			font-size: 
			h2 {
				font-size: 1.6em;
			}
			h3 {
				font-size: 1.4em;
			}
			h4 {
				font-size: 1.2em;
			}
			h5 {
				font-size: 1.1em;
			}
			.entry-thumbnail {
				margin-bottom: 16px;
			}
		}
	}
}
