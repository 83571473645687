$blue: #00a5e0;
$blue-dark: #00729a;
$blue-light: #ebf5f7;
$green: #00bcd4;
$black: #333;
$red: #cc0000;
$orange-dark: #db9500;
$gray-light: #f5f5f5;
$gray: #e9e9e9;
$yellow: #f2c336;

$my-colors: (
    "blue": #00a5e0,
    "blue-dark": #00729a,
    "blue-light": $blue-light,
    "black": #333,
    "red": #cc0000,
    "orange-dark": #db9500,
    "gray-light": #f5f5f5,
    "gray": #e9e9e9,
    "yellow": $yellow
);

@each $k, $v in $my-colors {
    .c-#{$k} { color: #{$v} !important; }
    .bg-#{$k} { background-color: #{$v} !important; }
}


.text-red {
    color: #e40404;
}
.font-weight-bold {
    font-weight: bold;
}

.table-sp-block {
    @media (max-width: 767px) {
        tbody {
            tr {
                th, td {
                    display: block;
                }
            }
        }
    }
}

.visible-sp {
    @media (min-width: 768px) {
        display: none !important;
    }
}
.visible-pc {
    @media (max-width: 767px) {
        display: none !important;
    }
}

/*------------------------------------
フォント
------------------------------------*/
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
// .ff_notosans {
//     font-family: 'Noto Sans JP', '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, sans-serif;
// }

@import url("https://use.typekit.net/fhp7evy.css");
.ff_futura {
    font-family: futura-pt, sans-serif;
    font-weight: 600;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
.ff_roboto_condensed {
    font-family: 'Roboto Condensed', sans-serif;
}


/*------------------------------------
Base
------------------------------------*/
body {
    font-family: 'Noto Sans JP', '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, sans-serif;
}

/*------------------------------------
Swiper
------------------------------------*/
#swiper-campaign {
    padding-bottom: 50px;
}
.swiper-button-next, .swiper-button-prev {
    top: 30%;
    width: 45px;
    height: 45px;
    background-size: 45px 45px;
}

.swiper-button-next {
    background-image: url(../img/base/swiper/next.png);
}
.swiper-button-prev {
    background-image: url(../img/base/swiper/prev.png);
}


/*------------------------------------
コンポーネント
------------------------------------*/
.c-cat {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    display: block;
    >.-inner {
        background-color: #cc0000;
        width: 8em;
        text-align: center;
        display: block;
    }

    &.program{
        >.-inner {
            background: #6190ff;
        }
    }
    &.support{
        >.-inner {
            background: #00885a;
        }
    }
    &.blog{
        >.-inner {
            background: #ffa900;
        }
    }
    &.top_news{
        >.-inner {
            background: #e5171f;
        }
    }
}

.c-btn-banner {
    img {
        width: 100%;
    }
}

.c-btn-icon {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px 10px;
    font-weight: bold;
    font-size: 20px;
    background-color: #fff;
    color: $black;
    text-align: center;
    >.-i {
        height: 24px;
        width: auto;
        margin-right: 5px;
    }
    &:hover {
        opacity: .7;
        color: $black;
    }
}

.c-btn-service {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px 20px;
    font-weight: bold;
    font-size: 20px;
    background-color: #f8f8f8;
    color: $black;
    display: block;
    position: relative;
    >.-inner {
        font-weight: bold;
        display: block;
        text-align: left;
        padding-left: 15px;
        >.-i {
            height: 32px;
            width: auto;
            margin-right: 10px;
        }
    }
    >.-inner + .-text {
        display: block;
        border-top: 1px solid #ccc;
        color: #666666;
        font-size: 14px;
        text-align: center;
        margin-top: 15px;
        padding: 15px 0 0 15px;
    }
    &:after {
        content: '';
        position: absolute;
        background-image: url(../img/base/icon/i_arrow_right_gr.svg);
        background-repeat: no-repeat;
        width: 10px;
        height: 10px;
        top: 30px;
        right: 20px;
        transition-duration: 200ms;
    }
    &:hover {
        opacity: .7;
        color: $black;
        &:after {
            right: 17px;
        }
    }
    &.-wh {
        background-color: #fff;
    }
}

.c-btn-more {
    font-size: 14px;
    color: $black;
    letter-spacing: .05em;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 25px 5px 10px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        background-image: url(../img/base/icon/i_arrow_right.svg);
        background-repeat: no-repeat;
        width: 10px;
        height: 10px;
        top: 10px;
        right: 3px;
        transition-duration: 200ms;
    }
    &:hover {
        color: $black;
        &:after {
            right: 0px;
        }
    }
}

.c-wrap-btn-right {
    display: flex;
    justify-content: flex-end;
}

.c-btn-contactbar {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    padding: 15px 5px;
    display: block;
    >.-i {
        height: 70px;
        display: block;
        margin: 0 auto 10px;
    }
    >.-txt {
        font-weight: bold;
        text-align: center;
        display: block;
        color: $black;
        font-size: 14px;
        >.-inner {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                background-image: url(../img/base/icon/i_arrow_right_yl.svg);
                background-repeat: no-repeat;
                width: 10px;
                height: 10px;
                top: 6px;
                right: -20px;
                transition-duration: 200ms;
            }
        }
    }
}



.area_title {
    margin-bottom: 30px;
    >.-title-en {
        @extend .ff_futura;
        text-align: center;
        font-size: 22px;
        padding: 0;
        margin: 0 0 5px;
        background: transparent;
        line-height: 1.2;
        >.-inner {
            display: inline-block;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: -20px;
                height: 30px;
                border-left: 2px solid #ccc;
                transform: rotate(-32deg);
            }
            &:after {
                content: '';
                position: absolute;
                right: -20px;
                height: 30px;
                border-left: 2px solid #ccc;
                transform: rotate(32deg);
            }
            &.c-black {
                &:before, &:after {
                    border-color: $black;
                }
            }
            &.c-blue {
                &:before, &:after {
                    border-color: $blue;
                }
            }
            &.c-orange-dark {
                &:before, &:after {
                    border-color: $orange-dark;
                }
            }
        }
    }
    >.-title {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        margin: 0 0 12px;
        border-bottom: none;
        padding: 0;
        line-height: 1.3;
        &:after {
            content: none;
        }
    }
    >.-lead {
        font-weight: bold;
        text-align: center;
        font-size: 16px;
        line-height: 1.6;
    }
    @media(max-width: 767px) {
        >.-title-en {
            font-size: 20px;
            margin-bottom: 12px;
            >.-inner {
                &:before, &:after {
                    height: 24px;
                }
            }
        }
        >.-title {
            font-size: 22px;
        }
    }
}


.c-panel-service {
    padding: 30px 15px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f8f8f8;
    >.-icon {
        height: 60px;
        margin: 0 auto 15px;
        display: block;
    }
    >.-title {
        font-weight: bold;
        font-size: 22px;
        text-align: center;
        margin: 0 0 10px;
        background-color: transparent;
        padding: 0;
        color: $black;
    }
    >.-lead {
        font-size: 15px;
        line-height: 1.5;
        text-align: center;
        color: #666666;
        margin: 0;
    }
}

.c-panel-support {
    padding: 50px 15px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f8f8f8;
    >.-wrap-icon {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            width: 120px;
            height: 120px;
            background-color: #fff;
            border-radius: 50%;
            top: -30px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        >.-icon {
            height: 60px;
            margin: 0 auto 10px;
            display: block;
            position: relative;
            z-index: 1;
        }
    }
    >.-title {
        font-weight: bold;
        font-size: 22px;
        text-align: center;
        margin: 0 0 5px;
        background-color: transparent;
        padding: 0;
        color: $black;
    }
    >.-lead {
        font-size: 15px;
        line-height: 1.5;
        text-align: center;
        color: #666666;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        min-height: 44px;
        >.-inner {
            >.-tel {
                display: block;
                margin: 0;
                color: $blue;
                font-size: 32px;
                font-weight: bold;
                @extend .ff_roboto_condensed;
                line-height: 1;
            }
        }
    }
}

.c-voice {
    padding: 0 20px 30px;
    position: relative;
    margin-top: 15px;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -10px;
        background-image: url(../img/base/voice/bg_voice.png);
        width: 115px;
        height: 56px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
    }
    >.-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        >.-icon {
            width: 46%;
        }
        >.-info {
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            >.-inner {
                .-area {
                    font-size: 16px;
                    >.-inner {
                        background-color: $yellow;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                        padding: 2px 4px;
                        line-height: 1.3;
                    }
                }
                >.-name {
                    font-size: 24px;
                    font-weight: bold;
                    font-style: italic;
                    >.-sm {
                        font-size: 16px;
                        margin-left: 3px;
                    }
                }
                >.-detail {
                    font-size: 13px;
                    font-style: italic;
                }
            }
        }
    }
    >.-content {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.8;
        >.-e {
            background-color: #d0ebf1;
        }
    }
    @media(max-width: 767px) {
        padding: 0 50px 30px;
        &:after {
            left: 10px;
        }
    }
}

>.-wrap-btn {
    display: flex;
    >.-item {
        >.-desc {
            text-align: justify;
            font-size: 15px;
            font-weight: bold;
            line-height: 1.6;
            margin-bottom: 8px;
        }
    }
}
