.p-title {
	&.-border-y {
		border-top: 2px solid #00a2e6;
		border-bottom: 2px solid #00a2e6;
		padding: 10px;
		font-size: 1.4em;
		font-weight: 600;
	}

	&.-bar {
		background-color: $blue;
		color: #333;
		padding: 10px 15px 8px;
		border-radius: 4px;
		&.-blue {
			background-color: $blue;
			color: #fff;
		}
	}
	&.-flag {
		padding: 10px 15px 8px;
		border-radius: 4px;
		background-color: #f8f8f8;
		&.-blue {
			border-left: 6px solid $blue;
		}
	}

	&.-h2 {
		font-size: 30px;
		@media screen and (max-width: 767px) {
			font-size: 22px;
		}
	}

	&.-area {
		font-size: 28px;
		font-weight: bold;
		text-align: center;
		margin: 0 0 30px;
		padding: 20px 5px;
		line-height: 1.3;
		background: #c11920;
		color: #fff;
		border-radius: 50px;
		> .-sm {
			font-size: 0.7em;
		}
	}

	&.-merit {
		position: relative;
		display: block;
		width: 90%;
		margin: 0 auto 30px;
		padding: 10px 0;
		background: #fdfc00;
		font-size: 1.4em;
		font-weight: bold;
		text-align: center;
		> span {
			color: #00a0e4;
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			left: 50%;
			bottom: -10px;
			transform: translate(-50%, 0);
			border-style: solid;
			border-width: 10px 10px 0 10px;
			border-color: #fdfd00 transparent transparent transparent;
		}
	}

	&.-arrow {
		background: transparent;
		border: none;
		margin: 0;
		padding: 0;
		box-shadow: none;
	}

	&.-plain {
		background: transparent;
		border: none;
		margin: 0;
		padding: 0;
		box-shadow: none;
		&:after {
			content: none;
		}
	}
}
