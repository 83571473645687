.p-faq {
	margin-bottom: 30px;
	padding-left: 35px;
	li {
		list-style-type: none;
		position: relative;
		&:before {
			content: 'Q.';
			position: absolute;
			left: -30px;
			font-size: 20px;
			font-weight: bold;
			color: #00729a;
		}
	}
}