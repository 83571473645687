.p-tv-list {
	margin-bottom: 20px;
	> .-title {
		display: block;
		color: #fff;
		margin-bottom: 5px;
		font-weight: bold;
		padding: 8px 20px;
		font-size: 20px;
	}
	> .-box {
		background-color: #eee;
		padding: 10px 10px 0;
		.-list {
			display: flex;
			flex-wrap: wrap;
			> span {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 4px;
				margin-right: 10px;
				margin-bottom: 10px;
				padding: 5px 15px;
				color: #222;
				font-weight: bold;
				font-size: 22px;
			}
		}
		> .-attention {
			margin-bottom: 10px;
			> .-title {
				font-weight: bold;
				font-size: 16px;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
				> img {
					margin-right: 5px;
				}
			}
			> .-sm {
				font-size: 14px;
				margin-bottom: 5px;
			}
			> .-inner {
				> img {
					margin-bottom: 5px;
				}
			}
		}
	}

	&.-pink {
		> .-title {
			background-color: #f75f6f;
		}
		> .-box {
			.-list {
				> span {
					background-color: #ffadb5;
				}
			}
		}
	}

	&.-green {
		> .-title {
			background-color: #16902e;
		}
		> .-box {
			.-list {
				> span {
					background-color: #c1db81;
				}
			}
		}
	}

	&.-yellow {
		> .-title {
			background-color: #cab400;
		}
		> .-box {
			.-list {
				> span {
					background-color: #fff33f;
				}
			}
		}
	}
}
