
.c-box-anshin-support {
    border: 8px solid $yellow;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    padding: 20px 40px;
    &:before {
        content: url(../img/main/cable-maruwakari/ribbon.png);
        position: absolute;
        top: 0;
        left: 30px;

    }
    >.-left {
        text-align: center;
    }
    >.-right {
        >.-inner {
            >.-title {
                color: $blue;
                font-weight: bold;
                font-size: 19px;
                line-height: 1.6;
                margin: 0 0 15px;
            }
            >.-text {
                font-size: 14px;
                line-height: 1.8;
                margin: 0;
            }
            >ul.-list {
                padding: 0;
                list-style-type: disc;
                margin: 0;
                >li {
                    display: inline-block;
                    line-height: 2;
                    margin: 0 15px 0 0;
                    &:before {
                        content: '・';
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 30px;
        >.-left {
            width: 20%;
        }
        >.-right {
            width: 75%;
        }
    }
    @media(max-width: 767px) {
        >.-left {
            margin-bottom: 30px;
        }
        >.-right {
            >.-inner {
                >.-title {
                }
            }
        }
    }
}
