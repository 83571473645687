.page-price-simulation {
	.js-simulation-wrap {
		margin-top: 50px;
		.-group {
			margin-bottom: 60px;
			input {
				margin-right: 5px;
				cursor: pointer;
				&:not(:first-of-type) {
					margin-left: 30px;
				}
			}
		}
	}
	.-result {
		max-width: 660px;
		margin: 0 auto;
	}
	@media(max-width: 767px) {
		.js-simulation-wrap {
			.-group {
				margin-bottom: 40px;
			}
		}
	}
}