.p-btn {
	&.-square {
		position: relative;
		display: block;
		font-weight: bold;
		padding: 0.25em 0.5em;
		text-decoration: none;
		color: #FFF;
		background: #00bcd4;
		transition: .3s;
		padding: 20px;
		border-radius: 8px;
		margin: 30px auto;
		width: 90%;
		font-size: 2rem;
		border: none;
	}
	&.-blue {
		background-color: $blue;
	}
	&.-green {
		background-color: $green;
	}
	&:hover {
		opacity: .7;
	}

	&.-default {
		background-color: $blue;
		display: inline-block;
		height: 40px;
		color: #fff;
		padding: 8px 15px;
		border-radius: 5px;
		position: relative;
		&.-icon {
			padding-left: 35px;
			&:before {
				content: '';
				position: absolute;
				left: 12px;
				top: 13px;
				height: 15px;
				width: 10px;
				background-image: url(../img/base/icon/i_arrow_right_wh.svg);
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
	}
}