
.p-flow {
  .table {
    width: 100%;

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 180px;
      height: 150px;
      background: #888;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: -20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 75px 0 75px 20px;
        border-color: transparent transparent transparent #888;
      }

      span {
        color: #fff;
        font-size: 1.6em;
        font-weight: bold;
        text-align: center;
      }
    }

    .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - 180px);
      height: 150px;
      margin: 0;
      padding: 0;
      background-size: 5px 5px;
      background-color: #fff;
      background-image: linear-gradient(-45deg, #f6f5f2 25%, transparent 25%, transparent 50%, #f6f5f2 50%, #f6f5f2 75%, transparent 75%, transparent);

      .inbox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 5px 25px;
        width: calc(100% - 30px);
        height: 130px;
        background: #fff;
        margin: auto;

        p {
          &.-title {
            font-size: 1.4em !important;
            font-weight: bold;
            margin: auto 0 10px;
          }
        }

        .flex-box {
          width: 100%;
          justify-content: flex-end;
        }

        p {
          &.big {
            font-size: 2.5em;
            line-height: 1;

            small {
              font-size: 0.65em;
            }
          }

          &.label {
            background: #7abb43;
            padding: 2px 32px;
            color: #fff;
            font-size: 1.1em;
            text-align: center;
            margin-right: 20px;
          }
        }
      }
    }
  }

  &.orenge .table {
    .left {
      background: #ff851e;

      &:after {
        border-color: transparent transparent transparent #ff851e;
      }
    }

    .right .inbox p.big {
      color: #ff851e;
    }
  }

  &.green .table {
    .left {
      background: #7abb43;

      &:after {
        border-color: transparent transparent transparent #7abb43;
      }
    }

    .right .inbox p.big {
      color: #7abb43;
    }
  }

  .notice {
    font-size: 1.4em;

    i {
      color: #ff851e;
    }

    span {
      color: #f00;
    }
  }

  @media (max-width: 767px) {
    .table {
      width: 100%;
      display: block;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: auto;
        background: #888;
        padding: 10px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: auto;
          bottom: -10px;
          left: 50%;
          width: 0;
          height: 0;
          transform: translate(-50%, 0);
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: #888 transparent transparent transparent;
        }

        span {
          color: #fff;
          font-size: 1.25em;
          font-weight: bold;
          text-align: center;
        }
      }

      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        background-size: 5px 5px;
        background-color: #fff;
        background-image: linear-gradient(-45deg, #f6f5f2 25%, transparent 25%, transparent 50%, #f6f5f2 50%, #f6f5f2 75%, transparent 75%, transparent);

        .inbox {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin: 0;
          padding: 20px 0 0;
          width: 100%;
          height: auto;
          background: #fff;
          margin: auto;


          p {
            text-align: center;
            font-size: 1em;
            margin: 0 auto;
            &.-title {
              display: block;
              width: 100%;
            }
          }

          .flex-box {
            display: block;
          }

          p {
            &.big {
              font-size: 2em;
              line-height: 1;
              margin-top: 20px;

              small {
                font-size: 0.5em;
              }
            }

            &.label {
              display: block;
              font-size: 1em;
              width: 80%;
              margin: 20px auto;
              padding: 2px;
            }
          }
        }
      }
    }

    &.orenge .table .left:after {
      border-color: #ff851e transparent transparent transparent;
    }

    &.green .table .left:after {
      border-color: #7abb43 transparent transparent transparent;
    }

    .notice {
      font-size: 1em;
      text-align: center;
    }
  }
}
