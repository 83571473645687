.page-cable-maruwakari {
	>.-area {
		padding: 60px 0;
    	.-attention {
    		font-size: 12px;
    		text-align: center;
    		margin-bottom: 30px;
    		line-height: 1.8;
    		>.-inner {
	    		background-color: #daebee;
    		}
    	}
	}
	>.-area-movie {
		margin-top: 30px;
		margin-bottom: 60px;
	}
    >.-area-about {
    	background-color: #eee;
    	position: relative;
    	&:before {
    		content: '';
    		position: absolute;
    		top: 0;
    		right: 0;
    		width: 56%;
    		height: 100%;
    		background-image: url(../img/main/cable-maruwakari/bg_area_about.jpg);
    		background-size: cover;
    		background-position: center;
    	}
    	.-box {
    		border: 1px solid #ccc;
    		background-color: rgba(#fff, .95);
    		padding: 50px 40px;
    		margin: 40px 0;
    		p {
    			margin: 0 0 2em;
    			font-size: 15px;
    			line-height: 1.6;
    			&:last-child {
    				margin: 0;
    			}
    		}
    	}
    	@media(max-width: 767px) {
    		&:before {
    			width: 100%;
    			height: 360px;
    		}
    		.-box {
    			margin-top: 260px;
    			padding: 30px 20px;
    		}
    	}
    }
    >.-area-area {
    	background-color: $blue-light;
    	padding: 60px 0;
    }
    >.-area-service {
    	.-list {
    		display: flex;
    		flex-wrap: wrap;
    		justify-content: space-between;
    		>.c-panel-service {
    			width: 32%;
    			margin-bottom: 15px;
    		}
    	}
    	@media(max-width: 767px) {
    		.-list {
    			justify-content: center;
    			>.c-panel-service {
    				width: 100%;
    				max-width: 280px;
    			}
    		}
    	}
    }
    >.-area-support {
    	background-color: $blue-light;
    	.-list {
    		display: flex;
    		flex-wrap: wrap;
    		justify-content: space-between;
    		margin-bottom: 40px;
    		>.c-panel-support {
    			width: 32%;
    			margin-bottom: 15px;
    		}
    	}
    	@media(max-width: 767px) {
    		.-list {
    			justify-content: center;
    			>.c-panel-support {
    				width: 100%;
    				max-width: 280px;
    			}
    		}
    	}
    }
    >.-area-voice {
    	.slider-voice {
    		padding-bottom: 50px;
    		>.swiper-pagination {
				bottom: 0;
    		}
    		>.swiper-button-prev, >.swiper-button-next {
    			top: 40%;
    		}
    		>.swiper-button-prev {

    		}
    		>.swiper-button-next {

    		}
    	}
    }
}